import React from 'react'
import styled from 'styled-components/macro'

import { RowBetween, RowFixed } from '../Row'
import { TYPE } from '../../theme'
import { IconButton } from './styleds'
import { RecoveryButton } from './styleds'
import { Repeat } from 'react-feather'
import useTheme from 'hooks/useTheme'

const StyledSwapHeader = styled.div`
  padding: 38px 38px 0px 38px;
  width: 100%;
  color: ${({ theme }) => theme.ssc4};
`

export default function SwapHeader({ handleRefresh, handlePendingClaims }: { handleRefresh?: () => void, handlePendingClaims?: () => void }) {
  const theme = useTheme()

  return (
    <StyledSwapHeader>
      <RowBetween>
        <RowFixed>
          <TYPE.black fontWeight={500} fontSize={26} style={{ marginRight: '8px' }}>
            Sigma
            <span style={{ color: '#635BFF' }}>Swap</span>
          </TYPE.black>
        </RowFixed>
        {handlePendingClaims ? (
            <RecoveryButton onClick={handlePendingClaims}>
              <span style={{ color: '#FFFFFF', fontWeight: 600, alignItems: "center" }}>Claim Recovery</span>
            </RecoveryButton>
        ) : null}
        {handleRefresh ? (
          <IconButton onClick={handleRefresh}>
            <Repeat size="22" color={theme.text1} style={{ marginBottom: '2px' }} />
          </IconButton>
        ) : null}
      </RowBetween>
    </StyledSwapHeader>
  )
}