import React from 'react'
import styled from 'styled-components'

const Background = styled.div`
  position: relative;
  height: 2rem;
  width: 100%;
  background: #e7e7e7;
  border-radius: 10px;
  overflow: hidden;
`

const Foreground = styled.div`
  position: absolute;
  height: 2rem;
  width: 0%;
  background: linear-gradient(278.37deg, #635bff 22.92%, #635bff 195.73%);
  border-radius: 10px;
  transition: width 180s;
`

export function ProgressBar({ value }: { value: number }) {
  return (
    <Background>
      <Foreground style={{ width: `${value}%` }} />
    </Background>
  )
}
