import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

export const MainWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 20px 38px 38px 38px;
`

export const IconButton = styled.button`
  padding: 0.5rem 0.5rem 0.25rem 0.5rem;
  border-radius: 0.5rem;
  border: 0;
  cursor: pointer;
`

export const RecoveryButton = styled.button`
  padding: 0.5rem 0.5rem 0.25rem 0.5rem;
  border-radius: 0.5rem;
  height: 39px;
  width: 150px;
  border: 0;
  cursor: pointer;
`

export const SectionBreak = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.bg3};
`

export const BottomGrouping = styled.div`
  margin-top: 30px;
`
