import React, { useMemo } from 'react'
import { Text, TextProps as TextPropsOriginal } from 'rebass'
import styled, {
  createGlobalStyle,
  css,
  DefaultTheme,
  ThemeProvider as StyledComponentsThemeProvider,
} from 'styled-components'
import { useIsDarkMode } from '../state/user/hooks'
import { Colors } from './styled'

export * from './components'

type TextProps = Omit<TextPropsOriginal, 'css'>

export const MEDIA_WIDTHS = {
  upToExtraSmall: 500,
  upToSmall: 720,
  upToMedium: 960,
  upToLarge: 1280,
}

const mediaWidthTemplates: {
  [width in keyof typeof MEDIA_WIDTHS]: typeof css
} = Object.keys(MEDIA_WIDTHS).reduce((accumulator, size) => {
  ;(accumulator as any)[size] = (a: any, b: any, c: any) => css`
    @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
      ${css(a, b, c)}
    }
  `
  return accumulator
}, {}) as any

const white = '#FFFFFF'
const black = '#000000'
const gray = '#647C93'
const blue = '#011C3A'

export function colors(darkMode: boolean): Colors {
  return {
    bg0: '',
    bg6: '',
    // silesiacoin colors
    ssc1: darkMode ? '#647C93' : '#F6F8FA',
    ssc2: darkMode ? '#647C93' : '#011C3A',
    ssc3: darkMode ? '#647C93' : '#011C3A',
    ssc4: darkMode ? '#647C93' : '#FFFFFF',
    ssc5: darkMode ? '#647C93' : '#8486AF',
    ssc6: darkMode ? '#647C93' : '#ECEDFC',
    ssc7: darkMode ? '#647C93' : '#ECEDFC',
    ssc8: darkMode ? '#647C93' : '#F6F8FA',
    ssc9: darkMode ? '#647C93' : '#F6F8FA',
    ssc10: darkMode ? '#647C93' : '#525583',
    ssc11: darkMode ? '#647C93' : '#131646',
    ssc12: darkMode ? '#647C93' : '#8486AF',
    ssc2Off: darkMode ? '#F647C93' : '#131646',
    mainBg: darkMode ? '#647C93' : '#363A7A',

    // base
    white,
    black,
    gray,
    blue,

    // text

    text1: darkMode ? '#FFFFFF' : '#FFFFFF',
    text2: darkMode ? '#8486AF' : '#092540',
    text3: darkMode ? '#898BA3' : '#8B8DA7',
    text4: darkMode ? '#FFFFFF' : '#131646',
    text5: darkMode ? '#635BFF' : '#635BFF',

    // backgrounds / greys
    bg1: darkMode ? '#647C93' : '#011C3A',
    bg2: darkMode ? '#647C93' : '#011C3A',
    bg3: darkMode ? '#647C93' : '#011C3A',
    bg4: darkMode ? '#647C93' : '#011C3A',
    bg5: darkMode ? '#647C93' : '#011C3A',

    //specialty colors
    modalBG: darkMode ? 'rgba(0,0,0,.425)' : 'rgba(0,0,0,0.3)',
    advancedBG: darkMode ? 'rgba(0,0,0,.425)' : 'rgba(255,255,255,1)',

    //primary colors
    primary1: '#2172E5',
    primary2: '#3680E7',
    primary3: '#4D8FEA',
    primary4: '#376bad70',
    primary5: '#F6F8FA',

    // color text
    primaryText1: darkMode ? '#FFFFFF' : '#131646',

    // secondary colors
    secondary1: '#2172E5',
    secondary2: darkMode ? '#17000b26' : '#F6DDE8',
    secondary3: darkMode ? '#17000b26' : '#FDEAF1',

    // other
    red1: '#FF4B4B',
    red2: '#FF4B4B',
    red3: '#D60000',
    green1: '#27AE60',
    yellow1: '#e3a507',
    yellow2: '#ff8f00',
    yellow3: '#F3B71E',
    blue1: '#2172E5',
    blue2: '#5199FF',

    error: '#FD4040',
    success: '#27AE60',
    warning: '#ff8f00',

    // dont wanna forget these blue yet
    // blue4: darkMode ? '#153d6f70' : '#C4D9F8',
    // blue5: darkMode ? '#153d6f70' : '#EBF4FF',
  }
}

export function theme(darkMode: boolean): DefaultTheme {
  return {
    ...colors(darkMode),

    grids: {
      sm: 8,
      md: 12,
      lg: 24,
    },

    //shadows
    shadow1: darkMode ? '#000' : '#2F80ED',

    // media queries
    mediaWidth: mediaWidthTemplates,

    // css snippets
    flexColumnNoWrap: css`
      display: flex;
      flex-flow: column nowrap;
    `,
    flexRowNoWrap: css`
      display: flex;
      flex-flow: row nowrap;
    `,
  }
}

export default function ThemeProvider({ children }: { children: React.ReactNode }) {
  const darkMode = useIsDarkMode()

  const themeObject = useMemo(() => theme(darkMode), [darkMode])

  return <StyledComponentsThemeProvider theme={themeObject}>{children}</StyledComponentsThemeProvider>
}

const TextWrapper = styled(Text)<{ color: keyof Colors }>`
  color: ${({ color, theme }) => (theme as any)[color]};
`

export const TYPE = {
  ssc(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'ssc12'} {...props} />
  },
  ssc2(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'ssc2Off'} {...props} />
  },
  main(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text2'} {...props} />
  },
  link(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'primary1'} {...props} />
  },
  label(props: TextProps) {
    return <TextWrapper fontWeight={600} color={'text1'} {...props} />
  },
  black(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text1'} {...props} />
  },
  white(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'white'} {...props} />
  },
  body(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={16} color={'text1'} {...props} />
  },
  largeHeader(props: TextProps) {
    return <TextWrapper fontWeight={600} fontSize={24} {...props} />
  },
  mediumHeader(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={20} {...props} />
  },
  subHeader(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={14} {...props} />
  },
  small(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={11} {...props} />
  },
  blue(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'blue1'} {...props} />
  },
  yellow(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'yellow3'} {...props} />
  },
  darkGray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text3'} {...props} />
  },
  gray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'bg3'} {...props} />
  },
  italic(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={12} fontStyle={'italic'} color={'text2'} {...props} />
  },
  error({ error, ...props }: { error: boolean } & TextProps) {
    return <TextWrapper fontWeight={500} color={error ? 'red1' : 'text2'} {...props} />
  },
}

export const ThemedGlobalStyle = createGlobalStyle`
html {
  color: ${({ theme }) => theme.text1};
  background-color: ${({ theme }) => theme.mainBg};
}

body {
  min-height: 100vh;
  background: rgb(169,185,255);
background: linear-gradient(15deg, rgba(169,185,255,1) 0%, rgba(255,228,228,1) 100%);
`
