import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { HashRouter } from 'react-router-dom'
import App from './pages/App'
import store from './state'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import ThemeProvider, { ThemedGlobalStyle } from './theme'
import { LanguageProvider } from './i18n'
import 'inter-ui'
import '@reach/dialog/styles.css'
import { Web3OnboardProvider } from 'context/Web3Onboard'

if (!!window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false
}

function Root() {
  return (
    <StrictMode>
      <Provider store={store}>
        <HashRouter>
          <LanguageProvider>
            <Web3OnboardProvider>
              <ThemeProvider>
                <ThemedGlobalStyle />
                <App />
              </ThemeProvider>
            </Web3OnboardProvider>
          </LanguageProvider>
        </HashRouter>
      </Provider>
    </StrictMode>
  )
}

ReactDOM.render(<Root />, document.getElementById('root'))

serviceWorkerRegistration.unregister()
