import { init, Web3OnboardProvider as DefaultWeb3OnboardProvider } from '@web3-onboard/react'
import type { InitOptions } from '@web3-onboard/core/dist/types'
import injectedModule, { ProviderLabel } from '@web3-onboard/injected-wallets'
import luksoModule from '@lukso/web3-onboard-config'
import React, { ReactElement } from 'react'
import LyxIcon from '../assets/images/lyx-icon.png'
import WlyxIcon from '../assets/images/wLYX.png'

const lukso = luksoModule()

export const UNIVERSAL_PROFILES_LABEL = 'Universal Profiles'

export const RPC_LUKSO = process.env.REACT_APP_RPC_LUKSO || 'https://rpc.lukso.gateway.fm/'
export const RPC_EVM = process.env.REACT_APP_RPC_EVM || 'https://rpc.eth.gateway.fm'

const injected = injectedModule({
  custom: [lukso],
  sort: (wallets) => {
    const sorted = wallets.reduce<any[]>((sorted, wallet) => {
      if (wallet.label === UNIVERSAL_PROFILES_LABEL) {
        sorted.unshift(wallet)
      } else {
        sorted.push(wallet)
      }
      return sorted
    }, [])
    return sorted
  },
  displayUnavailable: [UNIVERSAL_PROFILES_LABEL, ProviderLabel.MetaMask],
})

// NOTE: When connected as the UP switching the chain causes plugin to hang
export const chains: InitOptions['chains'] = [
  {
    id: 42,
    token: 'LYX',
    label: 'LUKSO Mainnet',
    rpcUrl: RPC_LUKSO,
    icon: LyxIcon,
  },
  {
    id: 1,
    token: 'ETH',
    label: 'Ethereum Mainnet',
    rpcUrl: RPC_EVM,
    icon: WlyxIcon,
  },
]

export const wrappedToken = 'wLYX'

const appMetadata = {
  name: 'SigmaSwap',
  icon: '/images/sigmaswap-icon.png',
  logo: '/images/sigmaswap-logo-white.png',
  description: 'Swap your LYX for wLYX on Ethereum Mainnet',
  recommendedInjectedWallets: [
    {
      name: UNIVERSAL_PROFILES_LABEL,
      url: 'https://chrome.google.com/webstore/detail/universal-profiles/abpickdkkbnbcoepogfhkhennhfhehfn?hl=en',
    },
  ],
}

const connect = {
  iDontHaveAWalletLink:
    'https://chrome.google.com/webstore/detail/universal-profiles/abpickdkkbnbcoepogfhkhennhfhehfn?hl=en',
}

const web3Onboard = init({
  appMetadata,
  connect,
  wallets: [injected],
  chains,
  theme: 'dark',
  accountCenter: {
    desktop: {
      enabled: true,
      position: 'topRight',
    },
    mobile: {
      enabled: true,
      position: 'bottomLeft',
    },
  },
})

export function Web3OnboardProvider({ children }: { children: ReactElement }) {
  return <DefaultWeb3OnboardProvider web3Onboard={web3Onboard}>{children}</DefaultWeb3OnboardProvider>
}