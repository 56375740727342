import {useConnectWallet, useSetChain, useWallets} from '@web3-onboard/react'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {AutoColumn} from '../../components/Column'
import {AutoRow} from '../../components/Row'
import {BottomGrouping, IconButton, MainWrapper} from '../../components/swap/styleds'
import SwapHeader from '../../components/swap/SwapHeader'
import AppBody from '../AppBody'
import {JsonRpcSigner} from '@ethersproject/providers'
import {Trans} from '@lingui/macro'
import {BigNumber, ContractTransaction, ethers} from 'ethers'
import {Text} from 'rebass'
import {Redirect} from 'react-router'
import bridgeAbi from 'abis/contracts/bridge.abi.json'
import wlyxAbi from 'abis/contracts/wlyx.abi.json'
import {ReactComponent as UpDownArrowSvg} from 'assets/svg/arrow_up_down.svg'
import {CONTRACT_BRIDGE, CONTRACT_WLYX} from 'constants/chain'
import {chains as CHAINS, UNIVERSAL_PROFILES_LABEL, wrappedToken} from 'context/Web3Onboard'
import {ProgressBar} from 'components/ProgressBar'
import {SwitchLocaleLink} from '../../components/SwitchLocaleLink'
import Popups from 'components/Popups'
import CurrencyInputPanelMulti from '../../components/CurrencyInputPanelMulti'
import {ButtonLight} from '../../components/Button'
import {getContract, getSigner} from 'utils'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

interface Thresholds {
  status: boolean
  lyx_threshold: string
  wlyx_threshold: string
}

interface Fees {
  wrap_fee: BigNumber
  unwrap_fee: BigNumber
}

export default function MultiSwap() {
  const connectedWallets = useWallets()
  const updateBalances = useConnectWallet()[3]
  const setPrimaryWallet = useConnectWallet()[5]

  const [{ connectedChain: universalProfileConnectedChain }, setUniversalProfileChain] =
    useSetChain(UNIVERSAL_PROFILES_LABEL)
  const [{ connectedChain: otherWalletConnectedChain }, setOtherWalletChain] = useSetChain(
    connectedWallets.find(({ label }) => label !== UNIVERSAL_PROFILES_LABEL)?.label
  )

  const connectedChain = useMemo(
    () => CHAINS.find(({ id }) => id === parseInt(connectedWallets[0]?.chains?.[0].id, 16)),
    [connectedWallets]
  )
  const currentAction: 'wrap' | 'unwrap' = useMemo(
    () => (connectedChain?.id === CHAINS[0].id ? 'wrap' : 'unwrap'),
    [connectedChain]
  )

  const handleSettingCorrectNetwork = useMemo(() => {
    if (parseInt(universalProfileConnectedChain?.id || '0', 16) !== CHAINS[0].id)
      return () => {
        setUniversalProfileChain({ chainId: `0x${CHAINS[0].id.toString(16)}` })
      }
    if (parseInt(otherWalletConnectedChain?.id || '0', 16) !== CHAINS[1].id)
      return () => {
        setOtherWalletChain({ chainId: `0x${CHAINS[1].id.toString(16)}` })
      }
    return
  }, [otherWalletConnectedChain, setOtherWalletChain, setUniversalProfileChain, universalProfileConnectedChain])

  const [amount, setAmount] = useState('')
  const [convertedAmount, setConvertedAmount] = useState('')
  const [loadingProgress, setLoadingProgress] = useState<number>()
  const [wrappedBalance, setWrappedBalance] = useState<string>()
  const [currentThresholds, setThresholds] = useState<Thresholds>()
  const [currentFees, setFees] = useState<Fees>()
  const [emptyNonces, setEmptyNonces] = useState<boolean>()

  const getWrappedBalance = useCallback(async () => {
    if (connectedWallets.length === 0) return
    const wallet = connectedWallets[currentAction === 'wrap' ? 1 : 0]
    const provider = new ethers.providers.Web3Provider(wallet.provider)
    const wlyxContract = getContract(CONTRACT_WLYX, wlyxAbi, provider, wallet.accounts[0].address)
    return ethers.utils.formatEther((await wlyxContract.balanceOf(wallet.accounts[0].address)).toString())
  }, [connectedWallets, currentAction])

  useEffect(() => {
    ;(async () => {
      if (!handleSettingCorrectNetwork) setWrappedBalance(await getWrappedBalance())
      setThresholds(await getThresholds())
      setFees(await getFees())
    })()
  // eslint-disable-next-line
  }, [getWrappedBalance, handleSettingCorrectNetwork])

  async function handleRefresh() {
    if (currentAction === 'unwrap') {
      // NOTE: This shouldnt work but it does, dont question it
      // For some reason balance of universal profile stored in app gets reset to zero if it isnt the primary wallet
      setPrimaryWallet(connectedWallets[1])
      await updateBalances()
      setPrimaryWallet(connectedWallets[0])
    } else {
      await updateBalances()
    }
    setWrappedBalance(await getWrappedBalance())
    setThresholds(await getThresholds())
    setFees(await getFees())
  }

  async function handlePendingClaims() {
    const recipientAddress = connectedWallets[1].accounts[0].address
    const {
      nonces
    } = await getUnrealizedNonces(recipientAddress)

    const itemsInArray = nonces.length

    if (itemsInArray === 0) {
      setEmptyNonces(true)
      return
    }

    const loadingProgressPeriod = 75
    const loadingProgressStart = 20

    setLoadingProgress(loadingProgressStart)

    const loadingProgressMultiplier = loadingProgressPeriod / itemsInArray

    let loadingProgress = loadingProgressStart
    try {
      for (const nonce of nonces) {
        loadingProgress += loadingProgressMultiplier
        setLoadingProgress(loadingProgress)

        const challenge = await getBackendChallenge(nonce)
        const provider = new ethers.providers.Web3Provider(connectedWallets[1].provider)
        const recipientSigner = getSigner(provider, recipientAddress)
        const {
          value,
          sig: { sig },
        } = await getChallengeSignature(recipientAddress, recipientSigner, nonce, challenge)
        const wlyxContract = getContract(CONTRACT_WLYX, wlyxAbi, provider, recipientAddress)

        await wlyxContract.mint(value, nonce, recipientAddress, sig.v, sig.r, sig.s)
        await updateBalances()
      }
      setLoadingProgress(95)
    } catch (err) {
      console.error(err)
    }
    setThresholds(await getThresholds())
    setFees(await getFees())
    setLoadingProgress(undefined)
  }

  async function getEstimateFee(): Promise<BigNumber | undefined> {
    if (connectedWallets.length === 0) return
    const wallet = connectedWallets[currentAction === 'wrap' ? 0 : 1]
    const provider = new ethers.providers.Web3Provider(wallet.provider)
    const bridgeContract = getContract(CONTRACT_BRIDGE, bridgeAbi, provider, wallet.accounts[0].address)
    return currentAction === 'wrap' ? await bridgeContract.feeWrap() : await bridgeContract.feeUnwrap()
  }

  async function getFees(): Promise<Fees | undefined> {
    if (connectedWallets.length === 0) return
    const wallet = connectedWallets[currentAction === 'wrap' ? 0 : 1]
    const provider = new ethers.providers.Web3Provider(wallet.provider)
    const bridgeContract = getContract(CONTRACT_BRIDGE, bridgeAbi, provider, wallet.accounts[0].address)
    const wrap_fee = await bridgeContract.feeWrap()
    const unwrap_fee = await bridgeContract.feeUnwrap()

    return {
      wrap_fee,
      unwrap_fee
    }
  }

  async function handleInputChange(value: string) {
    setAmount(value)
    const estimateFee = await getEstimateFee()
    const weiValue = value ? ethers.utils.parseEther(value) : undefined
    const valueWithoutFee = weiValue && estimateFee ? ethers.utils.formatEther(weiValue.sub(estimateFee)) : undefined
    setConvertedAmount(valueWithoutFee ? valueWithoutFee.toString() : value)
  }
  async function handleOutputChange(value: string) {
    setConvertedAmount(value)
    const estimateFee = await getEstimateFee()
    const weiValue = value ? ethers.utils.parseEther(value) : undefined
    const valueWithFee = weiValue && estimateFee ? ethers.utils.formatEther(weiValue.add(estimateFee)) : undefined
    setAmount(valueWithFee ? valueWithFee.toString() : value)
  }

  async function callDepositAndGetNonce(address: string, recipientAddress: string, amount: string) {
    const provider = new ethers.providers.Web3Provider(connectedWallets[0].provider)
    try {
      const bridgeContract = getContract(CONTRACT_BRIDGE, bridgeAbi, provider, address)
      const depositOptions = { value: ethers.utils.parseEther(amount) }
      const depositTransaction: ContractTransaction = await bridgeContract.deposit(recipientAddress, depositOptions)
      const depositReceipt = await depositTransaction.wait()
      const event = depositReceipt.events?.find(({ event }) => event === 'FundsReceived')
      return event?.args?.['nonce']
    } catch (err: any) {
      console.error(err)
      throw new Error(`Above error occured in getDepositNonce function: ${err.message}`)
    }
  }

  async function getThresholds() {
    try {
      while (true) {
        const currentThresholdsResponse = await fetch(`${BACKEND_URL}/thresholds`)
        const json = await currentThresholdsResponse.json()
        const thresholds: Thresholds = JSON.parse(JSON.stringify(json)) || {}
        if (currentThresholdsResponse.ok && currentThresholdsResponse.status) {
          return thresholds
        }
      }
    } catch (err: any) {
      console.error(err)
      throw new Error(`Above error occurred in getThreshold function: ${err.message}`)
    }
  }

  async function getUnrealizedNonces(recipientAddress: string) {
    try {
        const unrealizedNoncesResponse = await fetch(`${BACKEND_URL}/txs/${recipientAddress}`)
        return await unrealizedNoncesResponse.json()
    } catch (err: any) {
      console.error(err)
      throw new Error(`Above error occured in getUnrealizedNonces function: ${err.message}`)
    }
  }

  async function getBackendChallenge(depositNonce: string) {
    // TODO: Find some other way of waiting for this, since currently console is flodded with 404s
    try {
      while (true) {
        await new Promise(async (resolve) => setTimeout(resolve, 10000))
        const response = await fetch(`${BACKEND_URL}/tx/${depositNonce}/challenge`)
        const json = await response.json()
        if (response.ok) {
          return json.challenge
        }
      }
    } catch (err: any) {
      console.error(err)
      throw new Error(`Above error occured in getBackendChallenge function: ${err.message}`)
    }
  }

  async function getChallengeSignature(
    address: string,
    signer: JsonRpcSigner,
    depositNonce: string,
    challenge: string
  ) {
    try {
      setLoadingProgress(80)
      const signedChallenge = await signer.signMessage(challenge)

      setLoadingProgress(85)
      const backendTransactionResponse = await fetch(`${BACKEND_URL}/tx/${depositNonce}/signature`, {
        headers: {
          'X-Auth-Addr': address,
          'X-Auth-Sig': signedChallenge,
        },
      })
      return await backendTransactionResponse.json()
    } catch (err: any) {
      console.error(err)
      throw new Error(`Above error occured in getChallengeSignature function: ${err.message}`)
    }
  }

  async function handleWrapLyx(amount: string) {
    try {
      setLoadingProgress(0)
      const address = connectedWallets[0].accounts[0].address
      const recipientAddress = connectedWallets[1].accounts[0].address

      setLoadingProgress(10)
      const depositNonce = await callDepositAndGetNonce(address, recipientAddress, amount)
      setLoadingProgress(70)
      const challenge = await getBackendChallenge(depositNonce)

      const provider = new ethers.providers.Web3Provider(connectedWallets[1].provider)
      const recipientSigner = getSigner(provider, recipientAddress)
      const {
        value,
        sig: { sig },
      } = await getChallengeSignature(recipientAddress, recipientSigner, depositNonce, challenge)

      setLoadingProgress(95)
      const wlyxContract = getContract(CONTRACT_WLYX, wlyxAbi, provider, recipientAddress)
      await wlyxContract.mint(value, depositNonce, recipientAddress, sig.v, sig.r, sig.s)
      await updateBalances()
    } catch (err) {
      console.error(err)
    }
    setThresholds(await getThresholds())
    setFees(await getFees())
    setLoadingProgress(undefined)
  }

  async function handleUnwrapLyx(amount: string) {
    try {
      setLoadingProgress(0)
      const address = connectedWallets[0].accounts[0].address
      const recipientAddress = connectedWallets[1].accounts[0].address

      const provider = new ethers.providers.Web3Provider(connectedWallets[0].provider)
      const wlyxContract = getContract(CONTRACT_WLYX, wlyxAbi, provider, address)
      await wlyxContract.burnFor(recipientAddress, ethers.utils.parseEther(amount))

      setWrappedBalance(await getWrappedBalance())
    } catch (err) {
      console.error(err)
    }
    setThresholds(await getThresholds())
    setFees(await getFees())
    setLoadingProgress(undefined)
  }

  function handleSwapPrimaryWallet() {
    setPrimaryWallet(connectedWallets[1])
  }

  function handleThreshold(currentAction: string, inputDirection: string) {
    if ((currentAction === 'wrap' && inputDirection === 'to') || (currentAction === 'unwrap' && inputDirection === 'from')) {
      return currentThresholds?.wlyx_threshold != undefined ? ethers.utils.formatEther(currentThresholds?.wlyx_threshold) : "0"
    }

    return currentThresholds?.lyx_threshold != undefined ? ethers.utils.formatEther(currentThresholds?.lyx_threshold) : "0"
  }

  function isThresholdExceeded(amount: string, currentAction: string, currentThresholds?: Thresholds) {
    const inputAmount = parseFloat(amount)
    let threshold = 0

    switch (currentAction) {
      case 'wrap' :
        threshold = parseFloat(ethers.utils.formatEther(ethers.utils.parseUnits(currentThresholds?.wlyx_threshold || '0', 'wei')) || '0');
        break;
      case 'unwrap':
        threshold = parseFloat(ethers.utils.formatEther(ethers.utils.parseUnits(currentThresholds?.lyx_threshold || '0', 'wei')) || '0');
        break;
    }

    return inputAmount > threshold;
  }

  function isFeeExceeded(amount: string, currentAction: string, currentFees?: Fees) {
    if (amount === '') {
      return true
    }

    const inputAmount = ethers.utils.parseEther(amount);

    if (currentFees === undefined) {
      return true
    }

    if (currentAction === 'wrap') {
      return inputAmount.lte(currentFees.wrap_fee);
    }

    return inputAmount.lte(currentFees.unwrap_fee);
  }

  function handleInputAmountCheck(amount: string, currentAction: string, currentThresholds?: Thresholds) {
    if (amount === '') {
      return(
          <>
            <ButtonLight disabled={true}>
              <Text fontSize={20} fontWeight={500}>
                <Trans>Enter an amount</Trans>
              </Text>
            </ButtonLight>
          </>
      )
    }

    const feeExceeded = isFeeExceeded(amount, currentAction, currentFees);
    if (feeExceeded) {
      return (
          <>
            <ButtonLight disabled={true}>
              <Text fontSize={20} fontWeight={500}>
                <Trans>Value lower or equal to fee</Trans>
              </Text>
            </ButtonLight>
          </>
      )
    }

    if (isThresholdExceeded(amount, currentAction, currentThresholds)) {
      return(
          <>
            <ButtonLight disabled={true}>
              <Text fontSize={20} fontWeight={500}>
                <Trans>Amount exceeds threshold</Trans>
              </Text>
            </ButtonLight>
          </>
      )
    }

    return(
        <>
          <ButtonLight disabled={true}>
            <Text fontSize={20} fontWeight={500}>
              <Trans>Insufficient balance</Trans>
            </Text>
          </ButtonLight>
        </>
    )
  }

  function handleEmptyNoncesGoBackAction() {
    setEmptyNonces(false)
  }

  function handleThresholdsAndFees(amount: string, currentAction: string, currentThresholds?: Thresholds, currentFees?: Fees) {
    const feeExceeded = isFeeExceeded(amount, currentAction, currentFees);
    const thresholdExceeded = isThresholdExceeded(amount, currentAction, currentThresholds);

    return feeExceeded || thresholdExceeded;
  }

  if (
    connectedWallets.length < 1 ||
    (connectedWallets.length === 1 && !connectedWallets.findIndex(({ label }) => label === UNIVERSAL_PROFILES_LABEL))
  )
    return <Redirect to="/connect" />
  if (loadingProgress !== undefined)
    return (
      <AppBody>
        <MainWrapper id="loading">
          <div style={{ marginTop: '1rem' }} />
          <AutoColumn gap={'md'}>
            <Text fontSize={20} textAlign="center">
              Processing...
            </Text>
            {currentAction === 'wrap' ? <ProgressBar value={loadingProgress} /> : null}
            {loadingProgress === 70 ? (
              <Text textAlign="center">
                Waiting for block with transaction, please be patient (estimate: 3 minutes)
              </Text>
            ) : null}
          </AutoColumn>
        </MainWrapper>
      </AppBody>
    )
  if (emptyNonces)
    return (
      <AppBody>
        <SwapHeader />
        <MainWrapper id="empty-nonces">
          <AutoColumn gap={'md'}>
            <Text fontSize={20}>You are fine!</Text>
            <Text>
              It seems like you don&apos;t have pending transactions now.
            </Text>
          </AutoColumn>
          <BottomGrouping>
            <ButtonLight onClick={handleEmptyNoncesGoBackAction}>Go back</ButtonLight>
          </BottomGrouping>
        </MainWrapper>
      </AppBody>
    )
  if (handleSettingCorrectNetwork)
    return (
      <AppBody>
        <SwapHeader />
        <MainWrapper id="wrong-network">
          <AutoColumn gap={'md'}>
            <Text fontSize={20}>Oops!</Text>
            <Text>
              It seems like you&apos;re currently connected to the wrong network. No worries, we&apos;ve got a quick fix
              for you!
            </Text>
          </AutoColumn>
          <BottomGrouping>
            <ButtonLight onClick={handleSettingCorrectNetwork}>Change to the right network</ButtonLight>
          </BottomGrouping>
        </MainWrapper>
      </AppBody>
    )
  return (
    <>
      <Popups />
      <AppBody>
        <SwapHeader handleRefresh={handleRefresh} handlePendingClaims={handlePendingClaims} />
        <MainWrapper id="swap-page">
          <AutoColumn gap={'md'}>
            <CurrencyInputPanelMulti
              label="From"
              value={amount}
              onUserInput={handleInputChange}
              balance={currentAction === 'unwrap' ? wrappedBalance : undefined}
              balanceToken={currentAction === 'unwrap' ? wrappedToken : undefined}
              threshold={handleThreshold(currentAction, 'from')}
              id="ssc-swap-currency-input"
              index={0}
            />
            <AutoColumn justify="space-between">
              <AutoRow justify={'center'} style={{ padding: '0 1rem' }}>
                <IconButton onClick={handleSwapPrimaryWallet}>
                  <UpDownArrowSvg />
                </IconButton>
              </AutoRow>
            </AutoColumn>
            <CurrencyInputPanelMulti
              label="To"
              value={convertedAmount}
              onUserInput={handleOutputChange}
              balance={currentAction === 'wrap' ? wrappedBalance : undefined}
              balanceToken={currentAction === 'wrap' ? wrappedToken : undefined}
              threshold={handleThreshold(currentAction, 'to')}
              id="ssc-swap-currency-output"
              index={1}
            />
          </AutoColumn>
          <BottomGrouping>
            {parseFloat(amount) > 0 && parseFloat(amount) <=
                (currentAction === 'wrap' ? parseFloat(connectedWallets[0]?.accounts[0].balance?.[connectedChain?.token as string] || '0') : parseFloat(wrappedBalance || '0')) && !handleThresholdsAndFees(amount, currentAction, currentThresholds, currentFees) ? (
              <ButtonLight onClick={() => (currentAction === 'wrap' ? handleWrapLyx(amount) : handleUnwrapLyx(amount))}>
                <Text fontSize={20} fontWeight={500}>
                  <Trans>{currentAction === 'wrap' ? 'Wrap LYX' : 'Unwrap wLYX'}</Trans>
                </Text>
              </ButtonLight>
            ) : (
                handleInputAmountCheck(amount, currentAction, currentThresholds)
            )}
          </BottomGrouping>
        </MainWrapper>
      </AppBody>
      <SwitchLocaleLink />
    </>
  )
}
