import React, { useMemo } from 'react'
import styled from 'styled-components'
import { darken } from 'polished'
import { RowBetween } from '../Row'
import { TYPE } from '../../theme'
import { Input as NumericalInput } from '../NumericalInput'
import useTheme from '../../hooks/useTheme'
import { Trans } from '@lingui/macro'
import { useSetChain, useWallets } from '@web3-onboard/react'

const InputRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  padding: 0.75rem 0.75rem 0.75rem 1rem;
`

const LabelRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  color: #000;
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.75rem 1rem 0 1rem;
  span:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.2, theme.text2)};
  }
`

const TokenPanel = styled.div`
  padding: 0 0.5rem;
  height: 2.2rem;
  padding-top: 6px;
`

const TokenPanelImage = styled.div`
  float: left;
`

const TokenPanelText = styled.div`
  float: left;
  margin: 0 0.25rem 0 0.75rem;
  font-size: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.ssc2Off};
`

const InputPanel = styled.div<{ hideInput?: boolean }>`
  ${({ theme }) => theme.flexColumnNoWrap}
  position: relative;
  border-radius: ${({ hideInput }) => (hideInput ? '8px' : '20px')};
  background-color: ${({ theme }) => theme.bg2};
`

const Container = styled.div<{ hideInput: boolean }>`
  border-radius: ${({ hideInput }) => (hideInput ? '8px' : '10px')};
  background-color: ${({ theme }) => theme.ssc1};
`

interface CurrencyInputPanelProps {
  id: string
  value: string
  onUserInput?: (value: string) => void
  label?: 'From' | 'To'
  balance?: string
  balanceToken?: string
  threshold?: string
  index: number
}

export default function CurrencyInputPanelMulti({
  id,
  value,
  onUserInput,
  label,
  balance,
  balanceToken,
  threshold,
  index,
}: CurrencyInputPanelProps) {
  const theme = useTheme()

  const connectedWallets = useWallets()
  const [{ chains }] = useSetChain()

  const chain = useMemo(
    () =>
      chains.find(({ id }) =>
        connectedWallets.length > 1
          ? id === connectedWallets[index]?.chains[0].id
          : id === connectedWallets[0].chains[index]?.id
      ),
    [connectedWallets, index, chains]
  )
  return (
    <InputPanel id={id}>
      <Container hideInput={false}>
        <LabelRow>
          <RowBetween>
            <TYPE.body color={theme.text2} fontWeight={500} fontSize={14}>
              {label} chain ID {parseInt(chain?.id || '0', 16)}
            </TYPE.body>
            {chain?.token ? (
              <TYPE.body
                color={theme.text2}
                fontWeight={500}
                fontSize={14}
                style={{ display: 'inline', cursor: 'default' }}
              >
                <Trans>Balance:</Trans>{' '}
                {balance ? balance : connectedWallets[index].accounts[0].balance?.[chain.token].substring(0, 7)}
              </TYPE.body>
            ) : (
              <TYPE.body
                color={theme.text2}
                fontWeight={500}
                fontSize={14}
                style={{ display: 'inline', cursor: 'default' }}
              >
                -
              </TYPE.body>
            )}
          </RowBetween>
        </LabelRow>
        <InputRow>
          <NumericalInput
            style={{ textAlign: 'left' }}
            disabled={!onUserInput}
            className="token-amount-input"
            value={value}
            onUserInput={(val) => {
              if (onUserInput) {
                onUserInput(val)
              }
            }}
          />
          <TokenPanel>
            <TokenPanelImage>
              <img height={24} src={chain?.icon} alt={'icon'} />
            </TokenPanelImage>
            <TokenPanelText>{balanceToken ? balanceToken : chain?.token}</TokenPanelText>
          </TokenPanel>
        </InputRow>
        <LabelRow>
          <RowBetween>
            {chain?.token ? (
                <TYPE.body
                    color={theme.text2}
                    fontWeight={500}
                    fontSize={14}
                    style={{ display: 'inline', cursor: 'default' }}
                >
                  <Trans>Threshold:</Trans>{' '}
                  {threshold ? threshold : 0}
                </TYPE.body>
            ) : (
                <TYPE.body
                    color={theme.text2}
                    fontWeight={500}
                    fontSize={14}
                    style={{ display: 'inline', cursor: 'default' }}
                >
                  -
                </TYPE.body>
            )}
          </RowBetween>
        </LabelRow>
      </Container>
    </InputPanel>
  )
}
