import React, { useEffect, useState } from 'react'
import { AutoColumn } from '../../components/Column'
import { BottomGrouping, MainWrapper } from '../../components/swap/styleds'
import SwapHeader from '../../components/swap/SwapHeader'
import AppBody from '../AppBody'
import { ButtonLight } from '../../components/Button'
import { Text } from 'rebass'
import { Trans } from '@lingui/macro'
import { useConnectWallet } from '@web3-onboard/react'
import { useHistory } from 'react-router-dom'
import { UNIVERSAL_PROFILES_LABEL } from 'context/Web3Onboard'

export function ConnectWallet() {
  const history = useHistory()
  const [{ wallet, connecting }, connectWallet] = useConnectWallet()
  const setPrimaryWallet = useConnectWallet()[5]

  const [mounted, setMounted] = useState(false)
  const [connectingUP, setConnectingUP] = useState(false)
  const [connectingWallet, setConnectingWallet] = useState(false)

  async function handleConnectUP() {
    setConnectingUP(true)
    await connectWallet({ autoSelect: { label: UNIVERSAL_PROFILES_LABEL, disableModals: true } })
  }

  async function handleConnectWallet() {
    setConnectingWallet(true)
    const wallets = await connectWallet()
    setPrimaryWallet(wallets[1])
    history.push('/')
  }

  useEffect(() => {
    if (mounted && !connecting) {
      setConnectingWallet(false)
      setConnectingUP(false)
    }
  }, [connecting, mounted])

  useEffect(() => {
    setMounted(true)
    return () => {
      setMounted(false)
    }
  }, [])

  if (wallet?.label === UNIVERSAL_PROFILES_LABEL && !connectingUP)
    return (
      <AppBody>
        <SwapHeader />
        <MainWrapper id="connect-page">
          <AutoColumn gap={'md'}>
            <Text fontSize={20}>Unlock the full potential of our SigmaSwap by completing two quick steps:</Text>
            <Text>💼 Step 2: Link Your Ethereum Wallet</Text>
            <Text>
           
            </Text>
            <Text>Let&apos;s dive into the world of decentralized finance together! 🚀💱</Text>
          </AutoColumn>
          <BottomGrouping>
            <ButtonLight onClick={handleConnectWallet} disabled={connectingWallet}>
              <Text fontSize={20} fontWeight={500}>
                <Trans>{!connectingWallet ? 'Connect wallet' : 'Connecting...'}</Trans>
              </Text>
            </ButtonLight>
          </BottomGrouping>
        </MainWrapper>
      </AppBody>
    )
  return (
    <AppBody>
      <SwapHeader />
      <MainWrapper id="connect-page">
        <AutoColumn gap={'md'}>
          <Text fontSize={20}>Unlock the full potential of our SigmaSwap by completing two quick steps:</Text>
          <Text>  </Text>
          <Text>🔄 Step 1: Connect Your Universal Profile.</Text>
          <Text>
            
          </Text>
        </AutoColumn>
        <BottomGrouping>
          <ButtonLight onClick={handleConnectUP} disabled={connectingUP}>
            <Text fontSize={20} fontWeight={500}>
              <Trans>{!connectingUP ? 'Connect Universal Profile' : 'Connecting...'}</Trans>
            </Text>
          </ButtonLight>
        </BottomGrouping>
      </MainWrapper>
    </AppBody>
  )
}
