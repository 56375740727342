import React from 'react'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components/macro'
import Header from '../components/Header'
import { ConnectWallet } from './ConnectWallet'
import MultiSwap from './MultiSwap'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 120px;
  align-items: center;
  flex: 1;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 16px;
    padding-top: 6rem;
  `};
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
  position: fixed;
  top: 0;
`

const Marginer = styled.div`
  margin-top: 5rem;
`

export default function App() {
  return (
    <>
      <AppWrapper>
        <HeaderWrapper>
          <Header />
        </HeaderWrapper>
        <BodyWrapper>
          <Switch>
            <Route exact strict path="/" component={MultiSwap} />
            <Route exact strict path="/connect" component={ConnectWallet} />
          </Switch>
          <Marginer />
        </BodyWrapper>
      </AppWrapper>
    </>
  )
}
